import { Action } from 'redux'
import { RootState } from '../../store'

export const LOGOUT_START_ACTION = 'Logout/LOGOUT_START_ACTION'

export const LOGOUT_SUCCESS_ACTION = 'Logout/LOGOUT_SUCCESS_ACTION'

export const LOGOUT_RESET_ACTION = 'Logout/LOGOUT_RESET_ACTION'

export type LogoutStartAction = Action<typeof LOGOUT_START_ACTION>

export type LogoutSuccessAction = Action<typeof LOGOUT_SUCCESS_ACTION>

export type LogoutResetAction = Action<typeof LOGOUT_RESET_ACTION>

export type LogoutAction =
  | LogoutStartAction
  | LogoutSuccessAction
  | LogoutResetAction

export interface LogoutState {
  readonly logingOut: boolean
  readonly success: boolean
}

export interface LogoutRootState extends RootState {
  readonly logout: LogoutState
}
