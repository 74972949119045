import React, { memo } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useSession } from 'hooks/useSession'
import { User, Username, Avatar } from './useStyles'

const UserView = () => {
  const user = useSession()

  if (!user) {
    return <div />
  }

  return (
    <Paper square>
      <User>
        {user.profilePictureUri ? (
          <Avatar src={user.profilePictureUri} alt={user.name} />
        ) : (
          <Avatar>{user.name[0].toUpperCase()}</Avatar>
        )}

        <Username>{user.name}</Username>
        <Typography variant="caption" color="textSecondary">
          {user.email}
        </Typography>
      </User>
    </Paper>
  )
}

export default memo(UserView)
