import React, { FunctionComponent } from 'react'
import MuiContainer, {
  ContainerProps as MuiContainerProps,
} from '@mui/material/Container'
import { styled } from '@mui/material/styles'

const Root = styled(MuiContainer)`
  margin-top: ${({ theme }) => theme.spacing(12)};
`

export type ContainerProps = Pick<
  MuiContainerProps,
  'children' | 'style' | 'className'
>

const Container: FunctionComponent<ContainerProps> = (
  props: ContainerProps
) => {
  return <Root maxWidth="lg" {...props} />
}

export default Container
