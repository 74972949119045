import {
  SET_SESSION_ACTION,
  REMOVE_SESSION_ACTION,
  INIT_SESSION_ACTION,
} from './constants'
import type { SessionProviderState, SessionProviderReducer } from './types'

export const defaultState: SessionProviderState = {
  user: false,
  initialization: 'uninitialized',
}

const reducer: SessionProviderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_SESSION_ACTION:
      return { ...state, initialization: 'initializing' }
    case SET_SESSION_ACTION:
      return {
        ...state,
        initialization: 'initialized',
        user: action.payload.user,
        token: action.payload.token || state.token,
      }
    case REMOVE_SESSION_ACTION:
      return {
        ...state,
        initialization: 'initialized',
        user: false,
        token: undefined,
      }
    default:
      return state
  }
}

export default reducer
