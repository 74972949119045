import type { TitleReducer, TitleState } from './types'
import { TITLE } from './constants'

export const defaultState: TitleState = { title: '' }

export const reducer: TitleReducer = (state = defaultState, action) => {
  switch (action.type) {
    case TITLE:
      return { title: action.payload }
    default:
      return state
  }
}

export default reducer
