import { useSelector } from 'react-redux'
import type { RootState } from 'store'

export const useSession = (): UserSession | false => {
  return useSelector(({ session }: RootState) => session.user)
}

export const useToken = (): string | undefined => {
  return useSelector(({ session }: RootState) => session.token)
}
