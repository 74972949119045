import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TITLE } from './constants'
import { defaultState } from './reducer'
import type { TitleAction, TitleRootState, HeaderTitle, Link } from './types'

export type { Link, HeaderTitle }

export const useHeaderTitle = (): HeaderTitle => {
  return useSelector(
    ({ headerTitle = defaultState }: TitleRootState) => headerTitle.title
  )
}

export const useSetHeaderTitle = (title: HeaderTitle): void => {
  const dispatch = useDispatch()
  return useEffect(() => {
    dispatch<TitleAction>({ type: TITLE, payload: title })
  }, [dispatch, title])
}
