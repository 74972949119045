import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import type { SagaIterator } from 'redux-saga'
import client, { Response } from 'utils/axios'
import {
  INIT_SESSION_ACTION,
  REMOVE_SESSION_ACTION,
  SET_SESSION_ACTION,
} from './constants'
import { setSessionAction, removeSessionAction } from './actions'
import { makeGetProfile } from './services'
import { SetSessionAction } from './types'
import { TOKEN } from 'utils/constants'

export function* initSession(): SagaIterator {
  const getProfile = makeGetProfile(client)
  const token = localStorage.getItem(TOKEN)
  if (token) {
    const { data }: Response<UserSession> = yield call(getProfile)
    if (data) {
      yield put(setSessionAction(data, token))
    } else {
      yield put(removeSessionAction())
    }
  } else {
    yield put(removeSessionAction())
  }
}

const setToken = (token: string) => localStorage.setItem(TOKEN, token)

export function* setSession({ payload }: SetSessionAction): SagaIterator {
  if (payload.token) {
    yield call(setToken, payload.token)
  }
}

const removeToken = () => localStorage.removeItem(TOKEN)

export function* removeSession(): SagaIterator {
  yield call(removeToken)
}

export default function* sagas(): SagaIterator {
  yield all([
    takeLeading(INIT_SESSION_ACTION, initSession),
    takeEvery(SET_SESSION_ACTION, setSession),
    takeEvery(REMOVE_SESSION_ACTION, removeSession),
  ])
}
