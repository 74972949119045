import { styled } from '@mui/material/styles'

interface RootProps {
  readonly fullWidth?: boolean
}

export const Overlay = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Root = styled('div')<RootProps>`
  position: relative;
  display: ${({ fullWidth }) => (fullWidth ? '' : 'inline-block')};
`
