import React, { memo, FunctionComponent } from 'react'
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField'
import { useField, useFormikContext } from 'formik'

export interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'value' | 'onBlur' | 'onChange'> {
  readonly name: string
}

const TextField: FunctionComponent<TextFieldProps> = (
  props: TextFieldProps
) => {
  const [field, meta] = useField<string>(props.name)
  const { isSubmitting } = useFormikContext()
  return (
    <MuiTextField
      {...props}
      disabled={props.disabled === undefined ? isSubmitting : props.disabled}
      value={field.value}
      onBlur={field.onBlur}
      onChange={field.onChange}
      error={props.error || (meta.touched && !!meta.error)}
      helperText={
        props.error
          ? props.helperText
          : meta.touched && !!meta.error
          ? meta.error
          : props.helperText
      }
    />
  )
}

export default memo(TextField)
