import React, { FC } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export interface AlertDialogProps {
  readonly open: boolean
  readonly title?: string
  readonly description?: string
  readonly onClose?: () => void
  readonly positiveText?: string
  readonly onPositive?: () => void
  readonly negativeText?: string
  readonly onNegative?: () => void
  readonly children?: DialogContentProps['children']
}

const AlertDialog: FC<AlertDialogProps> = ({
  open,
  title,
  description,
  onClose,
  positiveText,
  onPositive,
  negativeText,
  onNegative,
  children,
}: AlertDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby={title ? 'alert-dialog-title' : 'alert-dialog-description'}
    aria-describedby="alert-dialog-description"
  >
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    <DialogContent>
      {children ? (
        children
      ) : description ? (
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      ) : undefined}
    </DialogContent>
    <DialogActions>
      {negativeText && (
        <Button onClick={onNegative} color="inherit">
          {negativeText}
        </Button>
      )}
      {positiveText && (
        <Button onClick={onPositive} color="primary">
          {positiveText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
)

export default AlertDialog
