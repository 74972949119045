/* eslint-disable @typescript-eslint/no-explicit-any */
export const undefinedIfEmpty = <T>(
  v?: T
): T extends null ? undefined : T | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    v === null || (v as any) === '' || (Array.isArray(v) && v.length === 0)
      ? undefined
      : v
  ) as any
}
