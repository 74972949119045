import { useMemo } from 'react'
import { useDispatch as useReduxDispatch } from 'react-redux'
import { LOAD_ACTION, RESET_ACTION } from './constants'
import type { LoadAction, ResetAction } from './types'

export interface Dispatch {
  readonly load: (id: number | string) => void
  readonly reset: () => void
}

export const useDispatch = (): Dispatch => {
  const dispatch = useReduxDispatch()
  return useMemo(
    () => ({
      load: (id) => {
        dispatch<LoadAction>({ type: LOAD_ACTION, payload: { id } })
      },
      reset: () => {
        dispatch<ResetAction>({ type: RESET_ACTION })
      },
    }),
    [dispatch]
  )
}

export default useDispatch
