import React, { ReactNode } from 'react'
import { useField, useFormikContext } from 'formik'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'

export interface SelectProps<T>
  extends Omit<MuiSelectProps<T>, 'value' | 'onBlur' | 'onChange'> {
  readonly name: string
  readonly helperText?: ReactNode
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
const Select = function <T>(props: SelectProps<T>): JSX.Element {
  const [field, meta] = useField(props.name)
  const { isSubmitting } = useFormikContext()
  const disabled = props.disabled === undefined ? isSubmitting : props.disabled
  return (
    <FormControl
      disabled={disabled}
      fullWidth={props.fullWidth}
      variant={props.variant}
    >
      <InputLabel id={`${field.name}-label`}>{props.label}</InputLabel>
      <MuiSelect
        {...props}
        disabled={disabled}
        id={`${field.name}-select`}
        labelId={`${field.name}-label`}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        error={props.error || (meta.touched && !!meta.error)}
      />
      <FormHelperText>
        {props.error
          ? props.helperText
          : meta.touched && !!meta.error
          ? meta.error
          : props.helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default Select
