import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const Root = styled('div')`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Message = styled(Typography)`
  color: red;
  text-align: center;
  align-self: stretch;
  margin-bottom: 16;
`

export interface ErrorRetryProps {
  readonly message: string
  readonly onRetry?: () => void
}

const ErrorRetry: FC<ErrorRetryProps> = ({
  message,
  onRetry,
}: ErrorRetryProps) => {
  return (
    <Root>
      <Message>{message}</Message>
      <Button disableElevation variant="contained" onClick={onRetry}>
        Retry
      </Button>
    </Root>
  )
}

export default ErrorRetry
