import React, { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Root } from './useStyles'

const FullProgress: FC = () => {
  return (
    <Root>
      <CircularProgress />
    </Root>
  )
}

export default FullProgress
