import type { SagaIterator } from 'redux-saga'
import { takeEvery, call, put } from 'redux-saga/effects'
import axios from 'utils/axios'
import {
  LogoutSuccessAction,
  LOGOUT_START_ACTION,
  LOGOUT_SUCCESS_ACTION,
} from './types'
import type { RemoveSessionAction } from '../SessionProvider/types'
import { REMOVE_SESSION_ACTION } from '../SessionProvider/constants'
import { makeLogout } from './services'

export function* logout(): SagaIterator {
  try {
    const logout = makeLogout(axios)
    yield call(logout)
    yield put<RemoveSessionAction>({ type: REMOVE_SESSION_ACTION })
    yield put<LogoutSuccessAction>({ type: LOGOUT_SUCCESS_ACTION })
  } catch (e) {
    yield put<RemoveSessionAction>({ type: REMOVE_SESSION_ACTION })
    yield put<LogoutSuccessAction>({ type: LOGOUT_SUCCESS_ACTION })
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(LOGOUT_START_ACTION, logout)
}
