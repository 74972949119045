import { useRef, MutableRefObject } from 'react'
import { FormikConfig, useFormik } from 'formik'

class Helper<V> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  value = useFormik<V>({ initialValues: {} as V, onSubmit: () => undefined })
}

type InnerRef<V> = FormikConfig<V>['innerRef']

export type FormikInstance<V> = Helper<V>['value']

export type FormikRef<V> = MutableRefObject<FormikInstance<V>> & InnerRef<V>

export const useFormikRef = <V>(): FormikRef<V> => {
  const formik = useRef<FormikInstance<V>>()
  return formik as FormikRef<V>
}
