import MuiAvatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const User = styled('div')`
  margin: ${({ theme }) => theme.spacing(2, 0, 2, 4)};
  display: flex;
  flex-direction: column;
`

export const Username = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`

export const Avatar = styled(MuiAvatar)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
`
