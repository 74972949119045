import {
  LogoutState,
  LogoutAction,
  LOGOUT_START_ACTION,
  LOGOUT_SUCCESS_ACTION,
  LOGOUT_RESET_ACTION,
} from './types'

export const defaultState: LogoutState = {
  logingOut: false,
  success: false,
}

const reducer = (
  state: LogoutState = defaultState,
  action: LogoutAction
): LogoutState => {
  switch (action.type) {
    case LOGOUT_START_ACTION:
      return { ...state, logingOut: true }
    case LOGOUT_SUCCESS_ACTION:
      return { ...state, logingOut: false, success: true }
    case LOGOUT_RESET_ACTION:
      return defaultState
    default:
      return state
  }
}

export default reducer
