import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  height: 100vh;
  align-items: stretch;
`

export const Content = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Title = styled(Typography)`
  flex-grow: 1;
`

export const Crumbs = styled(Breadcrumbs)`
  flex-grow: 1;
`
