import React, { FC, ReactNode } from 'react'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  SimplePaletteColorOptions,
} from '@mui/material/styles'

const primary: SimplePaletteColorOptions = {
  main: process.env.REACT_APP_COLOR_PRIMARY_MAIN!,
  dark: process.env.REACT_APP_COLOR_PRIMARY_DARK,
  light: process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
  contrastText: process.env.REACT_APP_COLOR_PRIMARY_CONTRAST_TEXT,
}

const secondary: SimplePaletteColorOptions = {
  main: process.env.REACT_APP_COLOR_SECONDARY_MAIN!,
  dark: process.env.REACT_APP_COLOR_SECONDARY_DARK,
  light: process.env.REACT_APP_COLOR_SECONDARY_LIGHT,
  contrastText: process.env.REACT_APP_COLOR_SECONDARY_CONTRAST_TEXT,
}

const theme = createTheme({
  palette: {
    primary,
    secondary,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        colorSecondary: {
          '&:hover .$MuiOutlinedInput-notchedOutline': {
            borderColor: secondary.dark,
          },
          '& fieldset': {
            borderColor: secondary.light,
          },
        },
        adornedEnd: {
          '& .$MuiInputAdornment-positionEnd': {
            color: secondary.main,
            '&:hover': {
              color: secondary.dark,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        colorSecondary: {
          '&:hover': {
            color: secondary.dark,
          },
          color: secondary.main,
        },
      },
    },
  },
})

export interface ThemeProviderProps {
  readonly children: ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
}: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
)

export default ThemeProvider
