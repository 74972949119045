import type { AxiosResponse } from 'axios'
import type { HttpClient } from 'utils/axios'
import { Values } from './types'

export const submit =
  (payload: Values, id?: number) =>
  (client: HttpClient): Promise<AxiosResponse> => {
    if (id) {
      return client.patch(`user/${id}`, payload)
    }
    return client.post('user', payload)
  }
