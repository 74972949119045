import { combineReducers, Reducer } from 'redux'
import sessionReducer from '../containers/SessionProvider/reducer'
import headerTitleReducer from '../Context/HeaderTitle/reducer'
import type { SessionProviderState } from 'containers/SessionProvider/types'
import type { TitleState } from '../Context/HeaderTitle/types'

export interface InjectedReducers {
  [k: string]: Reducer
}

export interface RootState {
  readonly session: SessionProviderState
  readonly headerTitle: TitleState
  readonly [k: string]: ReturnType<Reducer>
}

export type RootReducer = Reducer<RootState>

const createReducer = (injectedReducers: InjectedReducers): RootReducer =>
  combineReducers({
    session: sessionReducer,
    headerTitle: headerTitleReducer,
    ...injectedReducers,
  })

export default createReducer
