import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { Content, Description } from './useStyles'

export interface ProgressDialogProps {
  readonly open: boolean
  readonly title?: string
  readonly description: string
}

const ProgressDialog: FC<ProgressDialogProps> = ({
  open,
  title,
  description,
}: ProgressDialogProps) => {
  return (
    <Dialog
      open={open}
      aria-labelledby={
        title ? 'alert-dialog-title' : 'alert-dialog-description'
      }
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <Content>
        <Grid container spacing={2}>
          <Grid container item justifyContent="center" alignItems="center">
            <CircularProgress size={24} />
          </Grid>
          <Grid container item justifyContent="center" alignItems="center">
            <Description id="alert-dialog-description">
              {description}
            </Description>
          </Grid>
        </Grid>
      </Content>
    </Dialog>
  )
}

export default ProgressDialog
