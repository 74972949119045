import { call, put, takeEvery } from 'redux-saga/effects'
import axios, { Response } from 'utils/axios'
import type {
  LoadAction,
  LoadSuccessAction,
  LoadSuccessActionPayload,
} from './types'
import { loadData } from './services'
import type { LoadErrorAction } from './types'
import { ERROR_ACTION, LOAD_ACTION, SUCCESS_ACTION } from './constants'
import type { SagaIterator } from 'redux-saga'

type LoadResponse = Response<LoadSuccessActionPayload>

export const load = function* ({ payload }: LoadAction): SagaIterator {
  const load = loadData(payload)
  const { data, error }: LoadResponse = yield call(axios.tryRequest, load)

  if (error) {
    yield put<LoadErrorAction>({
      type: ERROR_ACTION,
      payload: { message: error.message },
    })
  } else if (data) {
    yield put<LoadSuccessAction>({
      type: SUCCESS_ACTION,
      payload: data,
    })
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(LOAD_ACTION, load)
}
