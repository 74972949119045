import React, { FC } from 'react'
import PrivateRoute, { PrivateRouteProps } from 'containers/PrivateRoute'
import MainPageWrapper, {
  MainPageWrapperProps,
} from 'containers/MainPageWrapper'

export type MainPrivateRouteProps = Omit<PrivateRouteProps, 'children'> &
  MainPageWrapperProps

const MainPrivateRoute: FC<MainPrivateRouteProps> = ({
  title,
  titles,
  children,
  element,
  ...props
}: MainPrivateRouteProps) => {
  return (
    <PrivateRoute {...props}>
      <MainPageWrapper key="main-page" title={title} titles={titles}>
        {element || children}
      </MainPageWrapper>
    </PrivateRoute>
  )
}

export default MainPrivateRoute
