import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateFnsUtils from '@date-io/date-fns'
import './index.css'
import App from './App'
import ThemeProvider from './components/ThemeProvider'
import SessionProvider from './containers/SessionProvider'
import createStore from './store'
// import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'

const store = createStore()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <SessionProvider>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </SessionProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
