import { useMemo } from 'react'
import * as yup from 'yup'
import { Values } from './types'

const factory = (): yup.SchemaOf<Values> =>
  yup.object({
    id: yup.number(),
    name: yup.string().required().label('Name'),
    email: yup.string().required().label('Email'),
    phone: yup.string().required().label('Phone'),
    companyName: yup.string().required().label('Company Name'),
    role: yup.string().label('Role'),
    customerType: yup
      .string()
      .when('role', {
        is: (v: string) => v === 'customer',
        then: yup.string().required(),
        otherwise: yup.string().optional(),
      })
      .label('Customer Type'),
    customerCategory: yup.string(),
    registrationType: yup
      .string()
      .when('role', {
        is: (v: string) => v === 'customer',
        then: yup.string().required(),
        otherwise: yup.string().optional(),
      })
      .label('Registration Type'),
    password: yup
      .string()
      .when('id', {
        is: (v: number) => !!v,
        then: yup.string().optional(),
        otherwise: yup.string().required(),
      })
      .label('Password'),
  })

const useValidationSchema = (): yup.SchemaOf<Values> =>
  useMemo(() => factory(), [])

export default useValidationSchema
