/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios'
import type { ErrorValidationItem } from 'common-types'
import { TOKEN } from './constants'

export type { AxiosResponse }

interface MClient extends AxiosInstance {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tryRequest: <T = any>(
    callback: (client: HttpClient) => Promise<AxiosResponse<T>>
  ) => Promise<Response<T>>
}

const baseURL: string = process.env.REACT_APP_REST_API_LOCATION!

export const BASE_URL = baseURL

const internal: MClient = axios.create({
  baseURL,
  withCredentials: true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any

const { interceptors } = internal

export interface ErrorData {
  readonly body?: ReadonlyArray<ErrorValidationItem>
  readonly headers?: ReadonlyArray<ErrorValidationItem>
  readonly cookies?: ReadonlyArray<ErrorValidationItem>
  readonly params?: ReadonlyArray<ErrorValidationItem>
  readonly query?: ReadonlyArray<ErrorValidationItem>
  readonly signedCookies?: ReadonlyArray<ErrorValidationItem>
}

export interface ResponseError<T = ErrorData> {
  readonly isHttpClientError?: true
  readonly code: number
  readonly message: string
  readonly details?: T
}

export type Response<D = unknown, T = ErrorData> =
  | {
      readonly data: D
      readonly error?: undefined
    }
  | {
      readonly data?: undefined
      readonly error: ResponseError<T>
    }

export interface Paged<T = unknown> {
  readonly page: number
  readonly perPage: number
  readonly count: number
  readonly data: ReadonlyArray<T>
}

export type PagedResponse<D = unknown> =
  | {
      readonly data: Paged<D>
      readonly error?: undefined
    }
  | {
      readonly data?: undefined
      readonly error: ResponseError
    }

export const tryRequest = async <D>(
  callback: Promise<AxiosResponse<D>>
): Promise<Response<D>> => {
  try {
    const { data } = await callback
    return { data }
  } catch (e) {
    const error = e as ResponseError
    if (error.isHttpClientError) {
      return { error }
    }
    return {
      error: { code: 1, message: (e as Error).message || 'Unknown Error' },
    }
  }
}

internal.tryRequest = (callback) => {
  return tryRequest(callback(internal))
}

export interface Status {
  readonly code: number
  readonly message: string
}

interface AxiosErrorData {
  readonly status: Status
  readonly code: number
  readonly message: string
  readonly details?: ErrorData
}

const errorInterceptor = async (e: any) => {
  const error: AxiosError<AxiosErrorData> = e
  if (error.isAxiosError) {
    if (error.response) {
      return Promise.reject({
        isHttpClientError: true,
        code: error.response.status,
        message:
          error.response.data?.message ||
          error.response.data?.status?.message ||
          error.message,
        details: error.response.data?.details,
      })
    }
    return Promise.reject({
      isHttpClientError: true,
      code: error.request ? 3 : 2,
      message: error.message,
    })
  }
  return Promise.reject({
    isHttpClientError: true,
    code: 1,
    message: error.message,
  })
}

interceptors.response.use(undefined, errorInterceptor)
interceptors.request.use((value) => {
  const token = localStorage.getItem(TOKEN)
  if (token && value.headers) {
    const headers = value.headers
    headers['Authorization'] = `Bearer ${token}`
  }
  return value
}, errorInterceptor)

export type HttpClient = Readonly<
  Pick<
    MClient,
    | 'request'
    | 'get'
    | 'delete'
    | 'head'
    | 'options'
    | 'post'
    | 'put'
    | 'patch'
    | 'tryRequest'
  >
>

const client: HttpClient = internal

export default client
