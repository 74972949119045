import React, { memo, useState, useCallback } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Dashboard from '@mui/icons-material/DashboardOutlined'
import ExitToApp from '@mui/icons-material/ExitToApp'
import ManageAccounts from '@mui/icons-material/ManageAccounts'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import DescriptionIcon from '@mui/icons-material/Description'
import EventIcon from '@mui/icons-material/Event'
import SupportIcon from '@mui/icons-material/SupportAgent'
import ImageIcon from '@mui/icons-material/Image'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import { useSession } from 'hooks/useSession'
import Logout from '../Logout'
import UserView from './UserView'
import Menu from 'components/DrawerMenuItem'
import CollapseMenu from './CollapseMenu'
import DownloadIcon from '@mui/icons-material/Download'
import ArticleIcon from '@mui/icons-material/Article'

export interface DrawerMenuProps {
  readonly open: boolean
  readonly onClose: () => void
}

const Drawer = styled('div')`
  min-width: 250px;
`

const DrawerMenu = ({ open, onClose }: DrawerMenuProps) => {
  const user = useSession()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const dismissConfirmation = useCallback(
    () => setShowConfirmation(false),
    [setShowConfirmation]
  )

  const closeDrawer = useCallback(
    (event: { type?: string; key?: string }) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return
      }
      onClose()
    },
    [onClose]
  )

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={closeDrawer}
        onOpen={closeDrawer}
      >
        <Drawer
          role="presentation"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
        >
          {user && (
            <>
              <UserView />
              <Menu to="/" icon={<Dashboard />} label="Dashboard" />
              <Divider />
              <Menu
                to="/certification"
                icon={<DescriptionIcon />}
                label="Certification"
              />
              <Menu
                to="/certification-session"
                icon={<DescriptionIcon />}
                label="User Certification"
              />
              <Menu
                to="/certificate"
                icon={<DescriptionIcon />}
                label="Certificate"
              />
              <Divider />
              <Menu to="/user" icon={<GroupRoundedIcon />} label="User" />
              <Menu to="/gallery" icon={<ImageIcon />} label="Gallery" />
              <Menu to="/event" icon={<EventIcon />} label="Event" />
              <Divider />
              <CollapseMenu label="Materi Pembelajaran" icon={<ArticleIcon />}>
                <Menu to="/materi-pembelajaran" label="List" />
                <Menu to="/category" label="Category" />
              </CollapseMenu>
              <Divider />
              <CollapseMenu label="Unduhan Rucika" icon={<DownloadIcon />}>
                <Menu to="/bim" label="Bim" />
                <Menu to="/brochure" label="Brochure" />
                <Menu to="/pricelist" label="Pricelist" />
              </CollapseMenu>
              <Divider />
              <Menu
                to="/support-message"
                icon={<SupportIcon />}
                label="Support Message"
              />
              <Menu to="/profile" icon={<ManageAccounts />} label="Profile" />
              <Menu
                onClick={() => setShowConfirmation(true)}
                icon={<ExitToApp />}
                label="Keluar"
              />
            </>
          )}
        </Drawer>
      </SwipeableDrawer>
      <Logout
        showConfirmation={showConfirmation}
        onDismissConfirmation={dismissConfirmation}
      />
    </>
  )
}

export default memo(DrawerMenu)
