import { FC } from 'react'
import { Form, FormikProps } from 'formik'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ProgressOverlay from 'components/ProgressOverlay'
import TextField from 'components/FormikTextField'
import type { Values } from './types'
import Select from 'components/FormikSelect'
import Autocomplete from 'components/FormikFreeAutoComplete'
import MenuItem from '@mui/material/MenuItem'

const categories = [
  'Owner/Developer/Stakeholder',
  'Konsultan/Perencana',
  'Kontraktor',
  'Akademisi',
  'Mitra Usaha/Strategic Partner',
  'Komunitas & Asosiasi',
]

const UserForm: FC<FormikProps<Values>> = ({
  values: { id, role },
  handleSubmit,
  isSubmitting,
}: FormikProps<Values>) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="name"
            variant="filled"
            label="Name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="email"
            variant="filled"
            label="Email"
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="phone"
            variant="filled"
            label="Phone"
            type="tel"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="companyName"
            variant="filled"
            label="Company Name"
          />
        </Grid>
        {!id && (
          <Grid item xs={12}>
            <Select
              fullWidth
              required
              name="role"
              variant="filled"
              label="Role"
            >
              <MenuItem value="super_admin">Super Admin</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </Grid>
        )}
        {role === 'customer' && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                name="customerCategory"
                label="Customer Type"
                data={categories}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                required
                name="customerType"
                variant="filled"
                label="Cakupan Proyek"
              >
                <MenuItem value="private">Private</MenuItem>
                <MenuItem value="state_owned">Pemerintah</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                required
                name="registrationType"
                variant="filled"
                label="Registration Type"
              >
                <MenuItem value="commercial">Commercial</MenuItem>
                <MenuItem value="institute">Institute</MenuItem>
              </Select>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            required={!id}
            name="password"
            variant="filled"
            label="Password"
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            disabled={isSubmitting}
            fullWidth
            variant="contained"
            color="primary"
          >
            <ProgressOverlay visible={isSubmitting} progressSize={20}>
              Submit
            </ProgressOverlay>
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

export default UserForm
