import React, { FunctionComponent, ReactNode } from 'react'
import MuiCollapse from '@mui/material/Collapse'
import ListItemText from '@mui/material/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import Menu from 'components/DrawerMenuItem'

export interface CollapseMenuProps {
  readonly children?: ReactNode
  readonly label?: string
  readonly icon?: JSX.Element
}

const Collapse = styled(MuiCollapse)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`

const CollapseMenu: FunctionComponent<CollapseMenuProps> = ({
  label,
  icon,
  children,
}: CollapseMenuProps) => {
  const [open, setOpen] = React.useState(false)
  const toggle = (event: React.MouseEvent) => {
    event.stopPropagation()
    setOpen(!open)
  }

  return (
    <>
      <Menu
        onClick={toggle}
        icon={icon}
        label={
          <>
            <ListItemText>{label}</ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
          </>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}

export default CollapseMenu
