import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { styled } from '@mui/material/styles'

export const Content = styled(DialogContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

export const Description = styled(DialogContentText)`
  margin-bottom: 0;
`
