import React, { useState, useCallback, ReactNode, FC } from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { SnackbarProvider } from 'notistack'
import { Root, Content, Title, Crumbs } from './useStyles'
import DrawerMenu from '../DrawerMenu'
import { useHeaderTitle, Link as LinkTitle } from 'Context/HeaderTitle'

interface TitleProps {
  readonly titles: ReadonlyArray<string | LinkTitle>
}

const CrumbsTitle = ({ titles }: TitleProps) => (
  <Crumbs color="inherit">
    {titles.map((t, i) =>
      typeof t === 'string' ? (
        <Typography color="inherit" key={i} variant="h6">
          {t}
        </Typography>
      ) : (
        <Typography
          color="inherit"
          key={i}
          variant="h6"
          component={Link}
          to={t.to}
        >
          {t.label}
        </Typography>
      )
    )}
  </Crumbs>
)

export interface MainPageWrapperProps {
  readonly children?: ReactNode
  readonly title?: ReactNode
  readonly titles?: ReadonlyArray<string | LinkTitle>
}

export const DefaultTitleComponent: FC = () => {
  const title = useHeaderTitle()
  if (typeof title === 'string') {
    return (
      <Title color="inherit" variant="h6">
        {title}
      </Title>
    )
  }
  if (title) {
    return <CrumbsTitle titles={title} />
  }
  return <>{}</>
}

const MainPageWrapper = ({ children, title, titles }: MainPageWrapperProps) => {
  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => setOpen(!open), [open, setOpen])
  return (
    <SnackbarProvider>
      <Root id="main-page" key="main-page">
        <DrawerMenu open={open} onClose={toggle} />
        <Content>
          <AppBar>
            <Toolbar>
              <IconButton onClick={toggle} color="inherit">
                <MenuIcon />
              </IconButton>
              {typeof title === 'string' ? (
                <Title color="inherit" variant="h6">
                  {title}
                </Title>
              ) : title ? (
                title
              ) : Array.isArray(titles) ? (
                <CrumbsTitle titles={titles} />
              ) : (
                <DefaultTitleComponent />
              )}
            </Toolbar>
          </AppBar>
          {children}
        </Content>
      </Root>
    </SnackbarProvider>
  )
}

export default MainPageWrapper
