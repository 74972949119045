import React, { memo, ReactElement, MouseEventHandler } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

const Link = styled(RouterLink)`
  text-decoration: none;
`

interface Props {
  readonly icon?: ReactElement
  readonly label?: string | ReactElement
  readonly disabled?: boolean
}

interface DrawerMenuItemLinkProps extends Props {
  readonly to: LinkProps['to']
  readonly onClick?: undefined
}

interface DrawerMenuItemButtonProps extends Props {
  readonly to?: undefined
  readonly onClick?: MouseEventHandler<HTMLDivElement>
}

type DrawerMenuItemProps = DrawerMenuItemLinkProps | DrawerMenuItemButtonProps

const DrawerMenuItem = ({
  to,
  icon,
  label,
  disabled,
  onClick,
}: DrawerMenuItemProps) => {
  const theme = useTheme()
  const link = (
    <ListItem
      button
      disabled={disabled}
      onClick={onClick}
      style={{
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(3),
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      {typeof label === 'string' ? <ListItemText primary={label} /> : label}
    </ListItem>
  )
  if (to) {
    return <Link to={to}>{link}</Link>
  }
  return link
}

export default memo(DrawerMenuItem)
