import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import MainPrivateRoute, {
  MainPrivateRouteProps,
} from '../containers/MainPrivateRoute'

export type MainPrivateRouteOutletProps = Omit<
  MainPrivateRouteProps,
  'element' | 'children'
>

const MainPrivateRouteOutlet: FC<MainPrivateRouteOutletProps> = (props) => {
  return <MainPrivateRoute {...props} element={<Outlet />} />
}

export default MainPrivateRouteOutlet
