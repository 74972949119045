import {
  RESET_ACTION,
  ERROR_ACTION,
  LOAD_ACTION,
  SUCCESS_ACTION,
} from './constants'
import type { UserState, UserReducer } from './types'

export const defaultState: UserState = {
  loading: false,
}

const reducer: UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_ACTION:
      return {
        ...state,
        loading: true,
      }
    case SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case ERROR_ACTION:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      }
    case RESET_ACTION:
      return defaultState
    default:
      return state
  }
}

export default reducer
