import type { UserState } from './types'
import { useInjectReducer } from 'utils/injectReducer'
import { useInjectSaga } from 'utils/injectSaga'
import saga from './saga'
import reducer from './reducer'
import useDispatch, { Dispatch } from './useDispatch'
import useSelector from './useSelector'

export type Redux = [UserState, Dispatch]

export const useRedux = (): Redux => {
  useInjectReducer({ key: 'userFormLoad', reducer })
  useInjectSaga({ key: 'userFormLoad', saga })
  const state = useSelector()
  const dispatch = useDispatch()

  return [state, dispatch]
}

export default useRedux
