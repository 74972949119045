import React, { FunctionComponent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  LogoutRootState,
  LogoutState,
  LogoutResetAction,
  LOGOUT_RESET_ACTION,
} from './types'
import { defaultState } from './reducer'
import AlertDialog from '../../components/AlertDialog'
import ProgressDialog from '../../components/ProgressDialog'
import reducer from './reducer'
import saga from './saga'
import { logoutStartActionCreator } from './actions'
import { useInjectReducer } from '../../utils/injectReducer'
import { useInjectSaga } from '../../utils/injectSaga'

export interface LogoutProps {
  readonly showConfirmation: boolean
  readonly onDismissConfirmation: () => void
}

const Logout: FunctionComponent<LogoutProps> = ({
  showConfirmation,
  onDismissConfirmation,
}: LogoutProps) => {
  useInjectReducer({ key: 'logout', reducer })
  useInjectSaga({ key: 'logout', saga })

  const dispacth = useDispatch()

  const logout = useSelector<LogoutRootState, LogoutState>(
    ({ logout = defaultState }) => logout
  )
  const handleLogout = () => {
    onDismissConfirmation()
    dispacth(logoutStartActionCreator())
  }
  useEffect(() => {
    if (logout.success) {
      return () => {
        dispacth<LogoutResetAction>({ type: LOGOUT_RESET_ACTION })
      }
    }
  }, [logout.success, dispacth])

  return (
    <>
      {showConfirmation && (
        <AlertDialog
          open={showConfirmation}
          onClose={onDismissConfirmation}
          description="Keluar?"
          positiveText="Keluar"
          onPositive={handleLogout}
          negativeText="Batal"
          onNegative={onDismissConfirmation}
        />
      )}
      {logout.logingOut && (
        <ProgressDialog open={true} description="Loging out ..." />
      )}
    </>
  )
}

export default Logout
