import { HttpClient, Response, tryRequest } from 'utils/axios'

export interface LoginResponseData {
  readonly user: UserSession
}

export type LoginResponse = Response<LoginResponseData>

export const makeLogout = (client: HttpClient) => (): Promise<LoginResponse> =>
  tryRequest(client.post('auth/logout'))
