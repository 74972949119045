import { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { stringify } from 'query-string'
import type { RootState } from 'store'
import type { SessionInitialization } from 'containers/SessionProvider/types'
import FullProgress from 'components/FullProgress'

const isProgress = (initialization: SessionInitialization) =>
  initialization === 'uninitialized' || initialization === 'initializing'

export interface PrivateRouteProps {
  readonly roles?: ReadonlyArray<UserSessionRole>
  readonly children?: ReactNode
  readonly element?: ReactNode
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  element,
  roles,
}: PrivateRouteProps) => {
  const location = useLocation()
  const { initialization, user } = useSelector(
    ({ session }: RootState) => session
  )

  if (initialization === 'initialized' && user) {
    if (roles && !!roles.length && !roles.some((r) => user.role === r)) {
      return <Navigate to="/" />
    }
    return <>{element || children}</>
  }
  if (isProgress(initialization)) {
    return <FullProgress />
  }
  return (
    <Navigate
      to={{
        pathname: '/login',
        search: `?${stringify({ from: location.pathname })}`,
      }}
    />
  )
}

PrivateRoute.defaultProps = {
  roles: [],
}

export default PrivateRoute
