/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense, ComponentType, FC } from 'react'

export interface ImportFunction<
  P = any,
  T extends ComponentType<P> = ComponentType<P>
> {
  (): Promise<{ default: T }>
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function Loadable<P = any, T extends ComponentType<P> = ComponentType<P>>(
  importFunc: ImportFunction<P, T>,
  { fallback = null } = { fallback: null }
): FC<P> {
  const LazyComponent: ComponentType<any> = lazy(importFunc)
  const LoadableComponent: FC<P> = (props: P) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  )
  return LoadableComponent
}

export default Loadable
