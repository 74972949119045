import { useMemo } from 'react'
import { useDispatch as useReduxDispatch } from 'react-redux'
import { START_ACTION, RESET_ACTION } from './constants'
import type { StartAction, ResetAction, Values } from './types'

export interface Dispatch {
  readonly submit: (payload: Values) => void
  readonly reset: () => void
}

export const useDispatch = (): Dispatch => {
  const dispatch = useReduxDispatch()
  return useMemo(
    () => ({
      submit: (payload: Values) => {
        dispatch<StartAction>({
          type: START_ACTION,
          payload,
        })
      },
      reset: () => {
        dispatch<ResetAction>({ type: RESET_ACTION })
      },
    }),
    [dispatch]
  )
}

export default useDispatch
