import React, {
  FC,
  ChangeEvent,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ListItem from '@mui/material/ListItem'
import CircularProgress from '@mui/material/CircularProgress'
import { useField, useFormikContext } from 'formik'
import { useDebounce } from 'hooks/useDebounce'

export interface V {
  readonly id?: number
  readonly name?: string
}

export interface AutoCompleteProps {
  readonly name: string
  readonly label?: string
  readonly disabled?: boolean
  readonly data: ReadonlyArray<string>
  readonly renderItem?: (
    prop: React.HTMLAttributes<HTMLLIElement>,
    item?: string
  ) => ReactNode
  readonly progress?: boolean
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
const AutoComplete = function ({
  name,
  label,
  disabled,
  data,
  renderItem,
  progress,
}: AutoCompleteProps): JSX.Element {
  const [field, meta, helper] = useField<string>(name)
  const { isSubmitting } = useFormikContext()
  const [query, setQuery] = useState('')
  const options = useMemo(() => {
    const result = data.filter((d) =>
      d.toLowerCase().includes(query.toLowerCase())
    )
    if (result.length) {
      return result
    }
    return [query]
  }, [query, data])

  return (
    <Autocomplete
      freeSolo
      fullWidth
      options={options}
      renderOption={(i, o) => {
        if (renderItem) {
          return renderItem(i, o)
        }
        return <ListItem {...i}>{o}</ListItem>
      }}
      disabled={isSubmitting || disabled}
      contentEditable={false}
      value={field.value}
      onChange={(_, v) => helper.setValue(v as string)}
      inputValue={query || ''}
      isOptionEqualToValue={(o, v) => {
        if (o === v) {
          return true
        }
        return false
      }}
      onInputChange={(_, v) => setQuery(v)}
      onBlur={field.onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          error={meta.touched && !!meta.error ? true : false}
          helperText={meta.touched ? meta.error : undefined}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {progress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : undefined}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default AutoComplete
