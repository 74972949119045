import { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Container from 'components/Container'
import ErrorRetry from 'components/ErrorRetry'

export interface FullProgressErrorRetryProps {
  readonly progress?: boolean
  readonly message?: string
  readonly onRetry?: () => void
}

const FullProgressErrorRetry: FC<FullProgressErrorRetryProps> = ({
  progress,
  message,
  onRetry,
}: FullProgressErrorRetryProps) => {
  return (
    <Container style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid container justifyContent="center" alignItems="center">
        {progress && <CircularProgress />}
        {!!message && <ErrorRetry message={message} onRetry={onRetry} />}
      </Grid>
    </Container>
  )
}

export default FullProgressErrorRetry
