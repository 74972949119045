import {
  ERROR_ACTION,
  RESET_ACTION,
  START_ACTION,
  SUCCESS_ACTION,
} from './constants'
import type { UserReducer, UserState } from './types'

export const defaultState: UserState = {
  loading: false,
  success: false,
  errorMessage: undefined,
}

export const reducer: UserReducer = (
  state = defaultState,
  action
): UserState => {
  switch (action.type) {
    case START_ACTION:
      return {
        ...state,
        errorMessage: undefined,
        loading: true,
        success: false,
      }
    case SUCCESS_ACTION:
      return {
        ...state,
        success: true,
        loading: false,
      }
    case ERROR_ACTION:
      return {
        ...state,
        success: false,
        loading: false,
        ...action.payload,
      }
    case RESET_ACTION:
      return defaultState
    default:
      return state
  }
}

export default reducer
