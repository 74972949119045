import { useSelector as useReduxSelector } from 'react-redux'
import { defaultState } from './reducer'
import type { UserRootState, UserState } from './types'

export const useSelector = (): UserState =>
  useReduxSelector(
    ({ userFormLoad = defaultState }: UserRootState) => userFormLoad
  )

export default useSelector
